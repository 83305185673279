<template>
  <div :id="'ank-'+datetime().format('YYYYMMDD')" class="timeline-wrapper bg-light mb-3 p-1 border bodrder-1">
    <div class="row text-center sticky-top">
      <div class="col">
        <div class="d-flex align-items-center justify-content-center bg-light p-2">
          <label class="pt-2 pb-2 pr-3 pl-3 mb-0 border border-1 rounded bg-white mr-4">{{datetime().format('YYYY年MM月DD日 (dd)')}}</label>
          <div class="btn-group">
            <a href="#ank-search-nav" class="btn btn-outline-dark bg-white" title="検索エリアに移動">
              <i class="fa fa-search"></i></a>
            <a :href="'#ank-'+datetime().add(-1, 'day').format('YYYYMMDD')"
               class="btn btn-outline-dark bg-white day-arrow-top"
               title="ひとつ上に移動">
              <i class="fa fa-arrow-up"></i></a>
            <a :href="'#ank-'+datetime().add(1, 'day').format('YYYYMMDD')"
               class="btn btn-outline-dark bg-white day-arrow-down"
               title="ひとつ下に移動">
              <i class="fa fa-arrow-down"></i></a>
          </div>
        </div>

      </div>
    </div>
    <div v-if="place" class="timeline-body custom-scroll mb-5 mt-n4">
      <div class="row" :style="styles.timelineBodyRow">
        <div class="col-12 chart-label">
          <div class="row">
            <div class="col">&nbsp;</div>
          </div>
          <!-- facility area -->
          <div v-if="facilities">
            <div v-for="row in facilities" :key="'label-' + row.code" class="row" :style="rowStyle(row.code)">
              <div v-if="!row.parent_code" class="col">{{row.parent_name}}</div>
              <div v-if="row.parent_code" class="col child">{{row.label}}</div>
            </div>
          </div>
        </div>
        <div class="col-12 chart-content">
          <!-- time area -->
          <div class="chart-header">
            <div v-for="row in place.times" :key="'header-' + row.start" class="cell">
              <time class="float-left">{{row.start}}時</time>
              <time class="float-right">{{row.end}}時</time>
            </div>
          </div>
          <div v-if="facilities" class="chart-body">
            <div v-for="fc in facilities" :key="'row-' + fc.code" class="facility-row" :style="rowStyle(fc.code)">
              <!-- timezone area -->
              <div v-for="time in fc.timezone" :key="fc.code + time.start"
                   :data-place_code="place.code"
                   :data-code="fc.code"
                   :data-date="datetime(time.start_time).format('YYYY-MM-DD')"
                   :data-start="datetime(time.start_time).format('HH:mm')"
                   :data-end="datetime(time.end_time).format('HH:mm')"
                   :style="timeBarStyle(fc.code,
                     datetime(time.start_time).format('YYYY-MM-DD HH:mm'),
                     datetime(time.end_time).format('YYYY-MM-DD HH:mm'))"
                   :class="timezoneClass(time.start_time, time.end_time, fc)">
              </div>
              <!-- reserved area -->
              <div v-if="reserves" class="reserves">
                <div v-for="item in reserves[fc.code]" :key="fc.code + item.id"
                     :data-place_code="place.code"
                     :data-code="item.facility_code"
                     :data-start="item.idle_used_at_from"
                     :data-end="item.idle_used_at_to"
                     :data-start_id="item.timezone_start_id"
                     :data-end_id="item.timezone_end_id"
                     :data-usage_id="item.usage_id"
                     :data-id="item.id"
                     :style="timeBarStyle(item.facility_code, item.idle_used_at_from, item.idle_used_at_to)"
                     :class="reserveClass(item, fc.code)">
                  <span class="text-white text-center d-block reserve-info-label" data-id="item.id">{{item.usage_id}} {{item.id}} {{item.facility_code}}</span>
                </div>
              </div>
              <div v-if="fc.holiday_times" class="holiday-times">
                <div v-for="item in filterHoliday(fc.holiday_times)" :key="item.start + item.end"
                     :style="timeBarStyle(fc.code, item.start, item.end)"
                     class="time-bar state-holiday"></div>
              </div>
            </div>
          </div>
          <!-- time area -->
          <div class="chart-footer">
            <div v-for="row in place.times" :key="'footer-' + row.start" class="cell">
              <time class="float-left">{{row.start}}時</time>
              <time class="float-right">{{row.end}}時</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'FacilityTimelineChart',
  props: {
    date : null,
    place : null,
    facilities : null,
    reserves : null,
    styles : null,
    cellWidth : null,
    cellHeight : null,
    facilityChecked :null,
    usageId : null,
  },
  data()
  {
    return {
      // conf
    }
  },
  created()
  {
    moment.locale('ja');
  },
  mounted()
  {
  },
  computed : {
    /**
     * タイムバーの大きさ、座標を計算
     * @returns {(function(*, *, *): ({}))|*}
     */
    timeBarStyle()
    {
      let self = this;

      return function(code, start, end)
      {
        if(!self.place)
        {
          return {};
        }
        let mBaseStart = moment(self.date + ' ' + self.place.open_time);
        let mStart = moment(start);
        let mEnd = moment(end);

        let xUnit = mStart.diff(mBaseStart, 'minute');
        let unit = mEnd.diff(mStart, 'minute');

        //console.log(mBaseStart.format('YYYY-MM-DD HH:mm'), start, mStart.format('YYYY-MM-DD HH:mm'), xUnit);
        return {
          width : self.cellWidth * (unit / 30) + 'px',
          height : self.cellHeight + 'px',
          top : '0',
          left : self.cellWidth * (xUnit / 30) + 'px',
        }
      }
    },
    /**
     * 予約可能エリアのクラスを返却
     * @param code
     * @param start
     * @param end
     * @returns {string[]}
     */
    reserveClass()
    {
      //let self = this;
      return function(reserve, facility_code)
      {
        let result = ['time-bar'];

        if(reserve.facility_code == facility_code)
        {
          result.push('state-' + reserve.state);
        }
        else
        {
          result.push('state-pc');
        }
        // 自身の予約
        if(this.usageId && this.usageId == reserve.usage_id)
        {
          result.push('state-self');
          result.push('js-self-bar');
        }

        return result;
      }
    },
    /**
     * タイムゾーンのクラス
     * @returns {function(*, *): string[]}
     */
    timezoneClass()
    {
      let self = this;
      return function(start, end, fc)
      {
        let mNow = moment();
        let mMinDate = null;
        if(fc.min_can_reserve)
        {
          mMinDate = moment(mNow.format('YYYY-MM-DD ' + start)).add(fc.min_can_reserve, 'days');
        }
        let mMaxDate = null;
        if(fc.max_can_reserve)
        {
          mMaxDate = moment(mNow.format('YYYY-MM-DD ' + end)).add(fc.max_can_reserve, 'days');
        }
        let mStart = moment(self.date + ' ' + start);
        let mEnd = moment(self.date + ' ' + end);
        let result = ['time-bar'];
        // 30分装飾
        if(mStart.format('mm') == '30')
        {
          result.push('half-l');
        }
        if(mEnd.format('mm') == '30')
        {
          result.push('half-r');
        }
        // 過去のタイムゾーンか？
        if(mNow.isAfter(mEnd))
        {
          result.push('state-past');
        }
        // 最小貸出可能日時か？
        else if(mMinDate && mMinDate.isAfter(mStart))
        {
          result.push('state-overflow');
        }
        // 最大貸出日時か？
        else if(mMaxDate && mStart.isAfter(mMaxDate))
        {
          result.push('state-overflow');
        }
        // 空き
        else
        {
          result.push('js-free-bar');
        }
        return result;
      }
    },
    /**
     * 施設の表示非表示
     * @returns {(function(*): ({display: string}))|*}
     */
    rowStyle() {
      let self = this;

      return function (code) {
        if(self.facilityChecked.indexOf(code) >= 0)
        {
          return {
            display : 'block'
          };
        }
        return {
          display : 'none'
        };
      }
    },
  },
  methods : {
    /**
     * 休日を該当するものだけにしておく
     * @param holidayTimes
     * @returns {*}
     */
    filterHoliday(holidayTimes)
    {
      let result = holidayTimes.filter(time => {
        return (moment(time.start).format('YYYYMMDD') == this.datetime().format('YYYYMMDD'));
      });
      return result;
    },
    /**
     * 指定範囲内にある予約取得（最初1件のみ）
     * @param code
     * @param start
     * @param end
     * @returns {null|*}
     */
    rangeReserve(code, mStart, mEnd)
    {
      let reserves = this.reserves;

      if(!reserves)
      {
        return null;
      }
      reserves = reserves[code];

      if(!reserves)
      {
        return null;
      }

      for(let r in reserves)
      {
        let item = reserves[r];

        // 親子どちらとも一致しないなら捜査せず
        if(item.facility_code != code && item.parent_facility_code != code)
        {
          continue;
        }
        let mItemStart = moment(item.idle_used_at_from);
        let mItemEnd = moment(item.idle_used_at_to);

        // console.log([
        //   mStart.format('YYYY-MM-DD HH:mm'),
        //   mEnd.format('YYYY-MM-DD HH:mm'),
        //   mItemStart.format('YYYY-MM-DD HH:mm'),
        //   mItemEnd.format('YYYY-MM-DD HH:mm')
        // ]);
        // 範囲内に予約がある場合
        if(mStart.isAfter(mItemEnd) && mEnd.isAfter(mItemStart))
        {
          return item;
        }
      }
      return null;
    },
    datetime(time='00:00')
    {
      return moment(this.date + ' ' + time).clone();
    }
  },
}
</script>
<style scoped>
.timeline
{
  position: relative;
}
.timeline .loading
{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: #000;
  opacity: 0.5;
  z-index:100;
  text-align: center;
}
.timeline .loading .spinner-border
{
  margin-top:100px;
}
.timeline .timeline-body
{
  width:100%;
  overflow-x: auto;
  max-width:1600px;
  padding:2px 5px;
}
.timeline .timeline-body > .row
{
  width:2000px;
  /*padding-left:20px;*/
}
.timeline .chart-label
{
  position:absolute;
  width:300px;
}

.timeline .chart-label .row
{
  margin:0;
  padding:0;
}
.timeline .chart-label .col
{
  padding: 0 0 0 10px;
  margin:0;
  height: 18px;
  margin-bottom: 52px;
  font-size: 12px;
  border: none;
  /*background: #ff1;*/
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  z-index:10;
  color:#fff;
}
/*.timeline .chart-label .row:first-child .col*/
/*{*/
/*  visibility: hidden;*/
/*}*/

.timeline .chart-label > .row
{
  margin-bottom: 2px;
}
.timeline .chart-content > .row
{
  margin-bottom: 2px;
}
.timeline .chart-label .col.child
{
  margin-left:15px;
}
.timeline .chart-label .col.child:before
{
  content:"― "
}
/* chart header */
.timeline .chart-header,
.timeline .chart-footer
{
  clear:both;
}
.timeline .chart-header .cell,
.timeline .chart-footer .cell
{
  width:100px; /* cell width */
  height:70px;
  float:left;
  padding:0;
  margin:0;
}
.timeline .chart-header .cell time
{
  line-height: 100px;
  margin-left:-14px;
}
.timeline .chart-footer .cell time
{
  line-height: 30px;
  margin-left:-14px;
}
.timeline .chart-header .cell time:last-child,
.timeline .chart-footer .cell time:last-child
{
  display: none;
}
.timeline .chart-header .cell:first-child time,
.timeline .chart-footer .cell:first-child time
{
  margin-left:0;
}
.timeline .chart-header .cell:last-child time:last-child,
.timeline .chart-footer .cell:last-child time:last-child
{
  margin-left:0;
  display: block;
}
/* chart body */
.timeline .chart-body
{
  position: relative;
}
.timeline .chart-body .facility-row
{
  position: relative;
  clear: both;
  width:100%;
  height:70px;
  border:1px solid #2e2e2e;
  background: #16161a;
}
.timeline .chart-body .cell
{
  width:50px; /* cell width */
  height:70px;
  float:left;
  border:1px solid #010101;
  background: #16161a;
}
.time-bar
{
  border-right:2px solid #010101;
  border-top:2px solid #010101;
  position: absolute;
  background: #3da9fc;
  opacity: 0.8;
  cursor: pointer;
}
.time-bar:hover
{
  border:1px solid #fff;
}
.time-bar.half-l
{
  border-left:1px dotted #2e2e2e!important;
}
.time-bar.half-r
{
  border-right:1px dotted #2e2e2e!important;
}
.time-bar.state-reserved,
.time-bar.state-maintenance,
.time-bar.state-request,
.time-bar.state-temp
{
  cursor: not-allowed;
  background: #e16162;
  z-index:3;
}
.time-bar.state-empty
{
  background: #3da9fc;
}
.time-bar.state-self
{
  cursor: pointer!important;
  background: #F8B042!important;
}
.time-bar.state-pc
{
  cursor: not-allowed;
  background-color:#666;
}
.time-bar.state-past,
.time-bar.state-overflow,
.time-bar.state-holiday
{
  cursor: not-allowed;
  background-color:#666;
}
.time-bar.state-holiday
{
  z-index: 2;
}

.timeline-wrapper
{

}

/* スクロールの幅の設定 */
.custom-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* スクロールの背景の設定 */
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(110, 108, 108, 0.2);
}
/* ドラックできるスクロール部の設定 */
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #666;
}
.timeline-body:first-child .day-arrow-top,
.timeline-body:last-child .day-arrow-down
{
  color:#ccc;
  cursor: not-allowed;
}
.reserve-info-label
{
  display: none!important;
}

</style>
