<template>
  <div class="timeline mt-3 mb-3" id="timeline-area">
    <div class="timeline-header p-3 mb-4 border border-1 rounded bg-light" id="ank-search-nav">
      <div class="row">

        <div class="col-12 text-center p-2">
          <div class="btn-group">
            <datepicker
                placeholder="ここをクリックして日時を入力"
                v-model="date"
                locale="jp"
                cancel-text="閉じる"
                select-text="選択"
                :format="'yyyy-MM-dd'"
                :preview-format="'yyyy年MM月dd日'"
                :enable-time-picker="false"
                :clearable="false"
            ></datepicker>
            <button type="button" class="btn btn-outline-dark btn-secondary text-white" title="前へ" v-on:click="prevDate()">
              <i class="fa fa-arrow-left"></i></button>
            <button type="button" class="btn btn-outline-dark btn-secondary text-white" title="次へ" v-on:click="nextDate()">
              <i class="fa fa-arrow-right"></i></button>
            <button type="button" class="btn btn-outline-dark js-reload-reserve" title="再読み込み" v-on:click="reloadReserve()"><i class="fa fa-refresh"></i></button>
          </div>
        </div>
      </div>
      <div v-if="place && facilities" class="row mt-2" style="max-height:300px;overflow-y: auto;">
        <div v-for="item in facilities" :key="'nav-'+item.code" class="col-12 col-sm-6 col-md-4 p-1">
          <div class="m-1 pt-2 pb-2 pl-3 border border-1 rounded bg-white h-100">
            <input type="checkbox" :id="'chk'+item.code" :value="item.code" v-model="facilityChecked" class="mt-0 mb-0 mr-2">
            <label :for="'chk'+item.code" class="m-0 p-0 mt-n2">
              <span>{{item.parent_name}}</span>
              <small v-if="item.label" class="m-0 p-0 mt-n2">（{{item.label}}）</small>
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-3">
        <div class="col-12 text-center">
          <small v-if="usageId" style="width:100px;" class="d-inline-block border border-1 p-2 m-1 text-white state-self">自身の予約</small>
          <small style="width:100px;" class="d-inline-block border border-1 p-2 m-1 text-white state-empty">空き</small>
          <small style="width:100px;" class="d-inline-block border border-1 p-2 m-1 text-white state-reserved">予約有</small>
          <small style="width:100px;" class="d-inline-block border border-1 p-2 m-1 text-white state-past">予約不可</small>
        </div>
      </div>
    </div>
    <div v-if="place">
      <TimelineChart v-for="d in dates" :key="d.format('YYYYMMDD')" ref="timelineChart"
                     :place="place"
                     :facilities="facilities"
                     :reserves="filReserveByDate(d.format('YYYY-MM-DD'))"
                     :date="d.format('YYYY-MM-DD')"
                     :styles="styles"
                     :cell-width="cellWidth"
                     :cell-height="cellHeight"
                     :facility-checked="facilityChecked"
                     :usage-id="usageId"></TimelineChart>
    </div>
    <div class="loading" :style="styles.loading">
      <span class="spinner-border text-light" role="status"></span>
    </div>
  </div>
  <!-- 予約画面で自分が選択した一覧を表示したい場合に使用。 -->
  <Teleport to="#self-reserved-list">
    <div v-if="usageId && selfReserves">
      <div v-for="item in selfReserves" :key="'self-'+item.id" class="card mb-2">
        <div class="card-body p-2 js-self-bar" style="cursor: pointer;"
             :data-place_code="place.code"
             :data-code="item.facility_code"
             :data-start="item.idle_used_at_from"
             :data-end="item.idle_used_at_to"
             :data-start_id="item.timezone_start_id"
             :data-end_id="item.timezone_end_id"
             :data-usage_id="item.usage_id"
             :data-id="item.id">
          <div class="clearfix">
            <div class="row">
              <div class="col-12 col-lg-5">
                <span class="mr-2 p-1 d-inline-block"><i class="fa fa-tag"></i>&nbsp;{{ item.used_at_from.format('YYYY年MM月DD日 (dd) HH:mm') }}～{{ item.used_at_to.format('HH:mm') }}</span>
              </div>
              <div class="col-12 col-lg-4">
                <span v-if="item.facility.parent_code" class="mr-2 p-1 text-muted d-inline-block">({{ item.facility.parent_name }} {{ item.facility.label }})</span>
                <span v-if="!item.facility.parent_code" class="mr-2 p-1 text-muted d-inline-block">({{ item.facility.parent_name }})</span>
              </div>
              <div class="col-12 col-lg-3 text-right">
                  <span class="mr-2 p-1 text-muted d-inline-block"
                        :data-facility-price="item.calc_total_price">{{ Number(item.calc_total_price).toLocaleString() }}円</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import TimelineChart from './components/FacilityTimelineChart'

export default {
  name: 'FacilityTimeline',
  components: {
    TimelineChart,
    Datepicker
  },
  props: {
    api_base_url : null,
  },
  data()
  {
    return {
      element : null,
      date : moment().format('YYYY-MM-DD'),
      dates : [], // 複数チャート表示管理用
      cellWidth : 50,
      cellHeight : 70,
      uiConfig : {
        initDay : 0,
        step : 1,
        stepUnit : 'day',
      },
      // conf
      chartConfig : {
        openTime : null,
        closeTime : null,
      },
      // data
      placeCode : null,
      place : null,
      facilities : null,
      reserves : null,
      facilityChecked : [],
      filterFacility : null,
      usageId : null,
      selfReserves : null,
      // style
      styles : {
        timelineBodyRow : {
          'width' : '0',
        },
        loading : {
          'display' : 'block',
        }
      },
    }
  },
  watch: {
    date: function(newVal)
    {
      this.date = moment(newVal).format('YYYY-MM-DD');
      this.changeDate();
    },
  },
  created()
  {
    moment.locale('ja');
  },
  mounted()
  {
    this.element = this.$el.parentElement;
    let self = this;
    this.loading(true);

    this.placeCode = this.element.dataset['place_code'];

    this.uiConfig.step = this.element.dataset['day_step'] ? parseInt(this.element.dataset['day_step']) : this.uiConfig.step;
    this.chartConfig.openTime = this.element.dataset['open_time'] ? this.element.dataset['open_time'] : null;
    this.chartConfig.closeTime = this.element.dataset['close_time'] ? this.element.dataset['close_time'] : null;

    this.uiConfig.initDay = this.element.dataset['init_day'] ? parseInt(this.element.dataset['init_day']) : this.uiConfig.init_day;

    // 初期日付操作
    this.date = this.datetime().add(this.uiConfig.initDay, 'day').format('YYYY-MM-DD');

    this.generateDates();

    // filter
    this.filterFacility = this.element.dataset['filter_facility'] ? this.element.dataset['filter_facility'] : null
    if(this.filterFacility)
    {
      this.filterFacility = this.filterFacility.split(',');
    }
    // 自身の予約
    this.usageId = this.element.dataset['usage_id'] ? parseInt(this.element.dataset['usage_id']) : null;

    // データ取得
    this.fetchPlace(this.placeCode, function()
    {
      // データ取得
      self.fetchFacilities(self.placeCode, function()
      {
        // filter指定がある場合
        if(self.filterFacility)
        {
          self.facilities = Object.values(self.facilities).filter(function(item){
            return (self.filterFacility.indexOf(item.code) >= 0)
          });
        }

        // 表示する施設
        let showFacility = self.element.dataset['show_facility'];
        if(showFacility)
        {
          self.facilityChecked = showFacility.split(',');
        }
        // 指定がなければデフォルトで全部表示
        else
        {
          self.facilityChecked = Object.values(self.facilities).map(item => {return item.code});
        }
        // 予約情報取得
        self.fetchReserves(self.placeCode, function()
        {
          self.loading(false);
        });
        // もしusageがあれば、usageに紐づいている予約を別途取得
        if(self.usageId)
        {
          self.fetchReservesByUsage(self.placeCode, self.usageId);
        }
      });
    });
  },
  methods : {
    /**
     * チャートのタイムライン定義を返す
     * @returns {*[]}
     */
    placeTimes(start, end)
    {
      let result = [];

      let date = this.date;
      let openTime = moment(date + ' ' + start);
      let closeTime = moment(date + ' ' + end);

      while(closeTime.isAfter(openTime))
      {
        result.push({
          start : openTime.format('H'),
          end : openTime.add(1, 'h').format('H')
        });
      }
      this.styles.timelineBodyRow = {
        'width' : result.length * (this.cellWidth * 2) + 30 + 'px'
      };
      return result;
    },
    /**
     * 施設データ取得
     * @param place
     * @returns {Promise<{}>}
     */
    fetchPlace(place, callback=null)
    {
      //let url = this.api_base_url + 'api/reserve/place.json';
      // @todo 大潟村専用対応
      let url = this.api_base_url + 'api/ogata/place.json';

      axios.get(url + '?code=' + place)
          .then(response => {
            let data = response.data.data;

            data.open_time = this.chartConfig.openTime ? this.chartConfig.openTime : data.open_time;
            data.close_time = this.chartConfig.closeTime ? this.chartConfig.closeTime : data.close_time;
            //alert (data.open_time);
            data['times'] = this.placeTimes(data.open_time, data.close_time);

            this.place = data;

            if(callback)
            {
              callback();
            }
          })
          .catch(error => console.log(error));
    },
    /**
     * 施設データ取得
     * @param place
     * @returns {Promise<{}>}
     */
    fetchFacilities(place, callback=null)
    {
      //let url = this.api_base_url + 'api/reserve/facilities.json';
      // @todo 大潟村専用対応
      let url = this.api_base_url + 'api/ogata/facilities.json';

      axios.get(url + '?place_code=' + place)
          .then(response => {
            var data = response.data.data;

            this.facilities = data;

            if(callback)
            {
              callback();
            }
          })
          .catch(error => console.log(error));
    },
    /**
     * 施設データ取得
     * @param place
     * @returns {Promise<{}>}
     */
    fetchReserves(place, callback=null)
    {
      //let url = this.api_base_url + 'api/reserve/reserve_facilities/' + place + '.json';
      // @todo 大潟村専用対応
      let url = this.api_base_url + 'api/ogata/reserve_facilities/' + place + '.json';
      let date = moment(this.date + ' 00:00');

      axios.get(url, {
        params : {
          'from' : date.format('YYYY-MM-DD 00:00:00'),
          'to'   : date.add(this.uiConfig.step, 'days').format('YYYY-MM-DD 00:00:00'),
        }
      }).then(response => {
            let data = response.data.data;

            if(data)
            {
              let reserves = [];
              let facilityCodes = [];
              let facilities = this.facilities;

              if(facilities)
              {
                Object.values(facilities).forEach(facility =>
                {
                  let code = facility.code;
                  let parentCode = facility.parent_code;
                  reserves[code] = [];
                  // 親子関係あらわした振り分けを行う。
                  facilityCodes[code] = Object.values(facilities).filter(item =>
                  {
                    return (code == item.code || code == item.parent_code || parentCode == item.code);
                  });
                });
                // 施設ごとに予約データを振りう分け（親と子に同じ予約が入る形になるので注意）
                for(let code in facilityCodes)
                {
                  reserves[code] = Object.values(data).filter(item =>
                  {
                    let exist = facilityCodes[code].map(item => {return item.code}).indexOf(item.facility_code);
                    return (exist >= 0);
                  });
                }
              }
              this.reserves = reserves;
            }
            //response.data;
            if(callback)
            {
              callback();
            }
          })
          .catch(error => console.log(error));
    },
    /**
     * 施設データ取得
     * @param place
     * @param usage_id
     * @param callback
     * @returns {Promise<{}>}
     */
    fetchReservesByUsage(place, usage_id, callback=null)
    {
      //let url = this.api_base_url + 'api/reserve/reserve_facilities/' + place + '.json';
      // @todo 大潟村専用対応
      let url = this.api_base_url + 'api/ogata/reserve_facilities/' + place + '.json';

      axios.get(url, {
        params : {
          'usage_id' : usage_id
        }
      }).then(response => {
        let data = response.data.data;
        if(data)
        {
          Object.values(data).map(item => {
            item.used_at_from = moment(item.used_at_from);
            item.used_at_to = moment(item.used_at_to);
            item.idle_before_time = moment(item.idle_before_time);
            item.idle_after_time = moment(item.idle_after_time);
            item.facility = this.facility(item.facility_code);
          });
          this.selfReserves = data;
        }
        //response.data;
        if(callback)
        {
          callback();
        }
      }).catch(error => console.log(error));
    },
    /**
     * 日付で予約データを絞り込み
     * @param date
     * @returns {{}}
     */
    filReserveByDate(date)
    {
      let result = {};
      let reserves = this.reserves;
      let mDate = moment(date);

      if(reserves)
      {
        for(let code in reserves)
        {
          result[code] = Object.values(reserves[code]).filter(item => {
            return mDate.format('YYYYMMDD') === moment(item.used_at_from).format('YYYYMMDD');
          });
        }
      }
      return result;
    },
    /**
     * 施設情報取得
     * @param code
     */
    facility(code)
    {
      if(this.facilities)
      {
        let result = Object.values(this.facilities).filter(item => {
          return (item.code === code)
        });
        return result ? result.shift() : result;
      }
      return {};
    },
    /**
     * 日付変更
     * @param date
     */
    changeDate()
    {
      let self = this;
      this.loading(true);
      this.fetchReserves(this.placeCode, function(){
        self.loading(false);
        self.generateDates();
      });
    },
    reloadReserve()
    {
      let self = this;

      this.loading(true);

      this.fetchReserves(this.placeCode, function(){
        self.loading(false);
      });

      if(this.usageId)
      {
        this.fetchReservesByUsage(this.placeCode, this.usageId);
      }
    },
    prevDate()
    {
      this.date = moment(this.date).add(this.uiConfig.step*-1, this.uiConfig.stepUnit).format('YYYY-MM-DD');
      // this.changeDate();
      return this;
    },
    nextDate()
    {
      this.date = moment(this.date).add(this.uiConfig.step, this.uiConfig.stepUnit).format('YYYY-MM-DD');
      // this.changeDate();
      return this;
    },
    loading(flag)
    {
      if(flag)
      {
        this.styles.loading = {'display' : 'block'};
      }
      else{
        this.styles.loading = {'display' : 'none'};
      }
    },
    datetime(time='00:00')
    {
      return moment(this.date + ' ' + time).clone();
    },
    generateDates()
    {
      let dates = [];
      for(let d=1; d <= this.uiConfig.step; d++)
      {
        dates.push(this.datetime().add((d-1), 'days'));
      }
      this.dates = dates
      return this;
    }
  },
}
</script>

<style scoped>
  .timeline
  {
    position: relative;
  }
  .timeline .loading
  {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: #000;
    opacity: 0.5;
    z-index:1000;
    text-align: center;
  }
  .timeline .loading .spinner-border
  {
    margin-top:100px;
  }
  .timeline .timeline-body
  {
    width:100%;
    overflow-x: auto;
    max-width:1600px;
    padding:2px 5px;
  }

  .timeline .timeline-body > .row
  {
    width:2000px;
    padding-left:20px;
  }
  .timeline .chart-label
  {
    position:absolute;
    width:300px;
  }

  .timeline .chart-label .row
  {
    margin:0;
    padding:0;
  }
  .timeline .chart-label .col
  {
    padding: 0 0 0 10px;
    margin:0;
    height: 18px;
    margin-bottom: 52px;
    font-size: 12px;
    border: none;
    /*background: #ff1;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    z-index:10;
    color:#fff;
  }
  /*.timeline .chart-label .row:first-child .col*/
  /*{*/
  /*  visibility: hidden;*/
  /*}*/

  .timeline .chart-label > .row
  {
    margin-bottom: 2px;
  }
  .timeline .chart-content > .row
  {
    margin-bottom: 2px;
  }
  .timeline .chart-label .col.child
  {
    margin-left:15px;
  }
  .timeline .chart-label .col.child:before
  {
    content:"― "
  }
  /* chart header */
  .timeline .chart-header,
  .timeline .chart-footer
  {
    clear:both;
  }
  .timeline .chart-header .cell,
  .timeline .chart-footer .cell
  {
    width:100px; /* cell width */
    height:70px;
    float:left;
    padding:0;
    margin:0;
  }
  .timeline .chart-header .cell time
  {
    line-height: 100px;
    margin-left:-14px;
  }
  .timeline .chart-footer .cell time
  {
    line-height: 30px;
    margin-left:-14px;
  }
  .timeline .chart-header .cell time:last-child,
  .timeline .chart-footer .cell time:last-child
  {
    display: none;
  }
  .timeline .chart-header .cell:first-child time,
  .timeline .chart-footer .cell:first-child time
  {
    margin-left:0;
  }
  .timeline .chart-header .cell:last-child time:last-child,
  .timeline .chart-footer .cell:last-child time:last-child
  {
    margin-left:0;
    display: block;
  }


  .state-reserved
  {
    cursor: not-allowed;
    background: #e16162;
  }
  .state-maintenance
  {
    cursor: not-allowed;
    background: #e16162;
  }
  .state-empty
  {
    background: #3da9fc
  }
  .state-past,
  .state-overflow
  {
    cursor: not-allowed;
    background-color:#666;
  }
  .state-self
  {
    cursor: not-allowed;
    background: #F8B042!important;
  }
</style>
