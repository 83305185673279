<template>
  <div class="row mb-5">
    <div class="col-md-4">
      <select v-model="placeSelected" class="form-control" v-on:change="changePlace">
        <option v-for="option in placeOptions" :key="option.code" :value="option.code" >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-12 mb-4" v-for="option in facilities" :key="option.code" :value="option.code">
      <div class="card mb-4 shadow-sm h-100 bg-light">
        <img class="card-img-top" :src="option.image_url" :alt="option.parent_name">
        <div class="card-body">
          <div class="text-center mb-3">
            <span class="d-block"><i class="fa-solid fa-warehouse"></i>&nbsp;{{option.full_name}}</span>
            <small class="text-muted d-block">{{ option.place_name }}</small>
          </div>
          <div class="text-center">
            <div class="btn-group">
              <a v-if="option.page_url" :href="option.page_url+'#ank-search-nav'" class="btn btn-sm btn-outline-secondary">
                <i class="fa fa-calendar"></i>&nbsp;空き状況</a>
              <a v-if="option.timeline_url" :href="option.timeline_url" target="_blank" class="btn btn-sm btn-outline-secondary">
                <i class="fa fa-calendar"></i>&nbsp;予約台帳</a>
              <a v-if="option.page_url" :href="option.page_url" class="btn btn-sm btn-outline-secondary">
                <i class="fa fa-info-circle"></i>&nbsp;施設情報</a>
            </div>
          </div>
          <p v-if="option.notes" class="card-text p-0 mt-2"><small class="text-muted">{{ option.notes }}</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FacilitySearch',
  props: {
    api_places_url : null,
    api_facilitis_url : null,
  },
  data()
  {
    return {
      placeSelected : '',
      placeOptions : [],
      facilities : []
    }
  },
  mounted(){
    this.getPlaces();
    this.getFacilities('');
  },
  methods : {
    getPlaces()
    {
      axios.get(this.api_places_url)
          .then(response => {
            var data = response.data.data;
            data[0] = {code : "", label : "-- 拠点を選択してください --"};
            this.placeOptions = data;
          })
          .catch(error => console.log(error));
    },
    getFacilities(place)
    {
      axios.get(this.api_facilitis_url + '?place_code=' + place)
          .then(response => {
            this.facilities = response.data.data;
          })
          .catch(error => console.log(error));
    },
    changePlace()
    {
      let place = this.placeSelected == undefined ? '' : this.placeSelected;
      this.getFacilities(place);
    }
  },
}
</script>

<style>
</style>
