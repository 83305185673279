<template>
  <div v-if="pages" class="list list-news">
    <ul class="list-group list-group-flush">
      <li v-for="page in pages" :key="page.id" class="list-group-item d-flex flex-wrap">
        <div class="d-flex col-12 col-md-auto p-1 mr-2">
          <div class="list-date ">{{ page.display_date }}</div>
          <div v-if="page.news_label" class="list-label">{{ page.news_label }}</div>
        </div>
        <div class="news-subject col-12 col-md-9 p-1">
          <a :href="page.anchor_front">{{ page.page_title }}</a>
        </div>
      </li>
    </ul>
  </div>
  <div v-if="is_paginate && this.paginate">
    <div class="paginate mt-4">
      <pagination v-model="currentPage"
                  :records="paginateTotalItems"
                  :per-page="perPage" @paginate="clickCallback"
                  :options="{
                  template : MyPagination,
                }"/>
    </div>
    <div class="text-center text-muted">
      <span>全{{ paginate.total_items }}件（{{ paginate.range_from }}件～{{ paginate.range_to }}件目表示）</span>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import Pagination from 'v-pagination-3';
import MyPagination from './components/MyPagination'

export default {
  name: 'PageList',
  components: {
    Pagination
  },
  props:
  {
    listName : null,
    api_page_list_url : null,
    is_paginate : null,
    limit : null,
    perPage : null,
  },
  data()
  {
    return {
      pages : [],
      currentPage : 1,
      paginate : null,
      MyPagination : null,
    }
  },
  created()
  {
    this.MyPagination = MyPagination;
    this.getPages();
  },
  methods :
  {
    getPages()
    {
      axios.get(this.api_page_list_url, {
        params : {
          limit : this.limit,
          is_paginate : this.is_paginate,
          per_page : this.perPage,
          p : this.currentPage,
        }
      }).then(response =>
      {
        this.pages = response.data.data.rows;
        this.paginate = response.data.data.paginate;

        if(this.pages.length <= 0)
        {
          this.hideElement();
        }
      }).catch(error => console.log(error));
    },
    clickCallback()
    {
      this.getPages();
    },
    hideElement()
    {
      // ページがなければ隠してくれる機能
      if(this.pages.length <= 0)
      {
        const items = document.querySelectorAll('.vue-'+this.listName+'-nolist');
        items.forEach(function(item)
        {
          item.classList.add('d-none');
        });
        return true;
      }
    }
  },
  computed :
  {
    paginateTotalItems()
    {
      return this.paginate.total_items ? this.paginate.total_items : 0;
    },
  }
}
</script>

<style>
.list-date
{
  width: 180px;
}
.list-label
{
  width: 100px;
}
</style>
