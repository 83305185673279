import { createApp } from 'vue';
import FacilitySearch from './FacilitySearch.vue';
import PageList from './PageList.vue';
import FacilityTimeline from './FacilityTimeline.vue';

// 施設一覧
createApp(FacilitySearch, {
    api_places_url : process.env.VUE_APP_API_BASE_URL + 'api/reserve/places.json',
    api_facilitis_url : process.env.VUE_APP_API_BASE_URL + 'api/reserve/parent_facilities.json'
}).mount('#vue-facility-search');

// お知らせ一覧（トップページ用）
createApp(PageList, {
    listName : "news",
    api_page_list_url : process.env.VUE_APP_API_BASE_URL + 'api/page/list/news.json',
    limit : 5
}).mount('#vue-news-list-index');

// お知らせ一覧（下層用）
createApp(PageList, {
    listName : "news",
    api_page_list_url : process.env.VUE_APP_API_BASE_URL + 'api/page/list/news.json',
    is_paginate : 1,
    perPage : 20
}).mount('#vue-news-list');

// タイムライン
createApp(FacilityTimeline, {
    api_base_url : process.env.VUE_APP_API_BASE_URL,
}).mount('#vue-facility-timeline');
